<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-08-05 18:36:24
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-05 20:00:54
-->
<template>
  <a-spin :spinning="spinning">
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="发货单号">
              <a-input v-model="searchData.shipCode" allowClear placeholder="请输入发货单号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="订单号">
              <a-input v-model="searchData.orderCode" allowClear placeholder="请输入订单号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="销售合同号">
              <a-input v-model="searchData.xsHeTong" allowClear placeholder="请输入销售合同号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="采购合同号">
              <a-input v-model="searchData.cgHeTong" allowClear placeholder="请输入采购合同号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.dealerName" allowClear placeholder="请输入经销商名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="制单人">
              <a-input v-model="searchData.createUser" allowClear placeholder="请输入制单人"></a-input>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="状态">
              <DictSelect
                field="shipStatus"
                :value.sync="searchData.deliveryStatus"
                style="width: 100%"
                placeholder="请选择状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>-->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="收货人">
              <a-input v-model="searchData.consignee" allowClear placeholder="请输入收货人"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="收货电话">
              <a-input v-model="searchData.phone" allowClear placeholder="请输入收货电话"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="收货地址">
              <PCD
                ref="pcd"
                placeholder="收货地址"
                :province.sync="searchData.province"
                :provinceId.sync="searchData.provinceId"
                :city.sync="searchData.city"
                :cityId.sync="searchData.cityId"
                :district.sync="searchData.area"
                :districtId.sync="searchData.areaId"
              ></PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="详细地址">
              <a-input v-model="searchData.address" allowClear :max-length="50" placeholder="请输入详细地址"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="推送K3">
              <DictSelect
                field="sendK3"
                :value.sync="searchData.sendK3"
                placeholder="是否推送至K3"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="舜天发货单号">
              <a-input v-model="searchData.saintyCode" allowClear placeholder="请输入舜天发货单号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="K3采购单号">
              <a-input v-model="searchData.k3PoNum" allowClear placeholder="请输入K3采购单号"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="K3操作人">
              <a-input v-model="searchData.kingdeeOperator" allowClear placeholder="请输入K3操作人"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="发货日期">
              <DateRange :startTime.sync="searchData.startDeliveryTime" :endTime.sync="searchData.endDeliveryTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="创建日期">
              <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button
          type="primary"
          v-if="isDeliver &&
            selectedRows.length > 0 && selectedRows[0].deliveryStatus == 0 && selectedRows[0].shippingOrderStatus == 2
          "
          @click="toHandler('ship')"
          >发货</a-button
        >
        <a-button
          type="primary"
          v-if="isAudit && selectedRows.length > 0 && selectedRows[0].shippingOrderStatus == 1"
          @click="toHandler('audit')"
          >审核</a-button
        >
        <a-button
          type="primary"
          v-if="isEditLogistics && selectedRows.length > 0 && selectedRows[0].deliveryType == 2 && selectedRows[0].deliveryStatus == 1"
          @click="toHandler('improve_logistics')"
          >完善物流</a-button
        >
        <a-button
          type="primary"
          v-if="isCheckLogistics && selectedRows.length > 0 && selectedRows[0].deliveryStatus != 0"
          @click="toHandler('view_logistics')"
          >查看物流</a-button
        >

        <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button v-if="isPush"  type="primary" @click="pushK3">推送到K3</a-button>
        <a-button
          type="default"
          v-if="isCancel && selectedRows.length > 0 "
          @click="toHandler('batchCancel')"
        >取消</a-button>
        <downLoad
          method="get"
          api="/api/order/system/orderShipInfo/excelExportOrderShipInfo"
          :params="selectedRows[0]"
          name="排货单.xlsx"
          v-if="isExportShipOrderDetail && selectedRows.length == 1"
        >导出排货单</downLoad
        >
        <downLoad v-if="isExport" method="post" api="/api/order/system/orderShipInfo/excelExportOrderShipList"
                  :params="searchDataObj"
                  @downLoadDone = "downLoadDone"
                  @startDownLoad = "startDownLoad"
                  name="菱感发货单(已发货).xls">导出发货单
        </downLoad>
      </div>

      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        :scroll="{ x: 1500 }"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="flagInvoice" slot-scope="text">
          {{ text == true ? '是' : '否' }}
        </span>
        <span slot="saintyCode" slot-scope="text,row">
          <span v-if="row.saintyCode != null">{{row.saintyCode}}</span>
          <span v-if="row.saintyCode == null">-</span>
        </span>
        <span slot="orderCode" slot-scope="text, row">
          <div v-for="(item, index) in row.orderCode" :key="index">
         <a @click="checkOrderInfo(item)"> {{item}} </a>
        </div>
        </span>
        <span slot="sendK3" slot-scope="text">
          <a-tag v-if="text == false" color="#e6a23c">未推送</a-tag>
          <a-tag v-if="text == true" color="#108ee9">已推送</a-tag>
        </span>
        <span slot="shippingOrderStatus" slot-scope="text">
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#67c23a">审核拒绝</a-tag>
        </span>
        <span slot="deliveryStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#e6a23c">待发货</a-tag>
          <a-tag v-if="text == 1" color="#108ee9">已发货</a-tag>
          <a-tag v-if="text == 2" color="#67c23a">已收货</a-tag>
        </span>
        <span slot="actualPrice" slot-scope="text">
          {{ text | formatMoney }}
        </span>
        <span slot="flagInvoice" slot-scope="text">
          {{ text == true ? '是' : '否' }}
        </span>
        <span slot="address" slot-scope="text, row">
          {{ row.consignee }},{{ row.phone }}<br />
          {{ row.provinceName }}{{ row.cityName }}{{ row.areaName }}{{ row.address }}
        </span>
      </a-table>

      <!-- 编辑表单 -->
      <OrderShipInfo-edit-modal ref="OrderShipInfoEditModal" @reload="getData"></OrderShipInfo-edit-modal>
      <ImproveLogistics ref="ImproveLogistics" @reload="getData"></ImproveLogistics>
      <CheckLogistics ref="CheckLogistics" @reload="getData"></CheckLogistics>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
<!--      <PushK3 ref="PushK3"></PushK3>-->


      <a-modal
        title="取消订单"
        width="30%"
        :visible="cancelVisible"
        :confirm-loading="confirmLoading"
        :footer="null"
        :maskClosable="false"
        @cancel="cancelVisible = false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
              <a-textarea v-model="cancelRemark" placeholder="取消原因"></a-textarea>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="cancelVisible = false">关闭</a-button>
          <a-button type="primary" :loading="cancelLoading" @click="cancelShipInfo">确定</a-button>
        </div>
      </a-modal>

      <a-modal v-model="visibleK3" title="推送K3" @ok="handleOkCharge"  @cancel="handleCanceCharge" :maskClosable="false" width="40%" :confirm-loading="confirmLoading">
        <a-form-model ref="formCharge" :model="k3Data" :rules="formRule" :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="营业担当" prop="bearId">
            <a-select placeholder="请选择" v-model="k3Data.bearId">
              <a-select-option :value="item.id" v-for="item in managerList" :key="item.id">
                【{{item.deptName}}】{{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 审核 -->
      <InvoiceReview ref="InvoiceReview" @reload="getData"></InvoiceReview>
    </div>
  </a-card>
  </a-spin>
</template>

<script>
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import { columns } from './components/colums.js'
import OrderShipInfoEditModal from './components/OrderShipInfoEditModal.vue'
import { delOrderShipInfo, listOrderShipInfo, selectByIdOrderShipInfo } from './api/OrderShipInfoApi'
import ImproveLogistics from './components/ImproveLogistics.vue'
import CheckLogistics from './components/CheckLogistics.vue'
import InvoiceReview from '@/views/order_ship_info/components/InvoiceReview.vue'
// import PushK3 from '@/views/audit_finish_order/components/PushK3';
import {checkPermission} from "@/utils/permissions";

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'orderShipInfo',
  components: {
    // PushK3,
    OrderShipInfoEditModal,
    ImproveLogistics,
    CheckLogistics,
    OrderInfoCheckModal,
    InvoiceReview
  },
  data() {
    return {
      columns: [
        {
          title: '经销商',
          dataIndex: 'dealerName',
          key: 'dealerName',
          width: 200,
          align: 'center',
          // ellipsis:true
        },
        {
          title: '供应商',
          dataIndex: 'supplierName',
          key: 'supplierName',
          width: 200,
          align: 'center',
          // ellipsis:true
        },
        {
          title: '发货单号',
          dataIndex: 'shipCode',
          key: 'shipCode',
          width: 200,
          align: 'center',
          // ellipsis:true
        },
        {
          title: '舜天发货单号',
          dataIndex: 'saintyCode',
          key: 'saintyCode',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'saintyCode'}
          // ellipsis:true
        },
        {
          title: '订单号',
          dataIndex: 'orderCode',
          key: 'orderCode',
          width: 170,
          align: 'center',
          scopedSlots: { customRender: 'orderCode'}
        },
        {
          title: '销售合同号',
          dataIndex: 'xsHeTong',
          key: 'xsHeTong',
          width: 170,
          align: 'left'
        },
        {
          title: '采购合同号',
          dataIndex: 'cgHeTong',
          key: 'cgHeTong',
          width: 170,
          align: 'left'
        },
        {
          title: '实际支付价',
          dataIndex: 'actualPrice',
          key: 'actualPrice',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'actualPrice'}
        },
        {
          title: '是否推送至K3',
          dataIndex: 'sendK3',
          key: 'sendK3',
          width: 170,
          align: 'center',
          scopedSlots: { customRender: 'sendK3'}
        },
        {
          title: 'K3采购单',
          dataIndex: 'k3PoNum',
          key: 'k3PoNum',
          width: 170,
          align: 'center'
        },
        {
          title: 'K3操作人',
          dataIndex: 'kingdeeOperator',
          key: 'kingdeeOperator',
          width: 170,
          align: 'center'
        },
        // {
        //   title: '订单号',
        //   dataIndex: 'saintyCode',
        //   key: 'saintyCode',
        //   width: 150,
        //   align: 'center',
        //   scopedSlots: { customRender: 'saintyCode'}
        // },
        {
          title: '订单商品总数量',
          dataIndex: 'goodsCount',
          key: 'goodsCount',
          width: 100,
          align: 'center',
          // ellipsis:true
        },
        {
          title: '已发商品数量',
          dataIndex: 'alreadySendGoodsCount',
          key: 'alreadySendGoodsCount',
          width: 100,
          align: 'center',
          // ellipsis:true
        },
        {
          title: '实发商品发数量',
          dataIndex: 'realSendGoodsCount',
          key: 'realSendGoodsCount',
          width: 100,
          align: 'center',
          // ellipsis:true
        },
        {
          title: '剩余可发数量',
          dataIndex: 'remainingQuantity',
          key: 'remainingQuantity',
          width: 100,
          align: 'center',
        },
        {
          title: '此次发货数量',
          dataIndex: 'shipNum',
          key: 'shipNum',
          width: 100,
          align: 'center',
        },
        {
          title: '发货状态',
          dataIndex: 'deliveryStatus',
          key: 'deliveryStatus',
          align: 'center',
          width: 100,
          scopedSlots: { customRender: 'deliveryStatus'}
        },
        {
          title: '审核状态',
          dataIndex: 'shippingOrderStatus',
          key: 'shippingOrderStatus',
          align:'center',
          width: 100,
          scopedSlots: { customRender: 'shippingOrderStatus'}
        },

        // {
        //   title: '件数',
        //   dataIndex: 'caseNumber',
        //   key: 'caseNumber',
        //   align: 'center',
        //   // ellipsis:true
        // },
        // {
        //   title: '总重量',
        //   dataIndex: 'grossWeight',
        //   key: 'grossWeight',
        //   align: 'center',
        //   // ellipsis:true
        // },
        // {
        //   title: '运费',
        //   dataIndex: 'freight',
        //   key: 'freight',
        //   width:80,
        //   align: 'center',
        //   // ellipsis:true
        // },
        {
          title: '是否开票',
          dataIndex: 'flagInvoice',
          key: 'flagInvoice',
          width: 150,
          align: 'center',
          // ellipsis:true
          scopedSlots: {customRender: 'flagInvoice'}
        },
        {
          title: '收货信息',
          dataIndex: 'address',
          key: 'address',
          width: 300,
          ellipsis:true,
          scopedSlots: {customRender: 'address'}
        },
        {
          title: '制单人',
          dataIndex: 'createUser',
          key: 'createUser',
          align: 'center',
          width: 80,
        },
        {
          title: '发货日期',
          dataIndex: 'deliveryTime',
          key: 'deliveryTime',
          align: 'center',
          width: 200,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center',
          width: 200,
          key: 'createTime'
        },
        {
          title: '操作时间',
          dataIndex: 'modifyTime',
          align: 'center',
          width: 200,
          key: 'modifyTime'
        },
      ],
      confirmLoading: false,
      visible: false,
      rejectReason: '',
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      // page: Object.assign({}, pageSource),
       page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      isCheck: checkPermission('order:ship:check'),
      isAudit: checkPermission('order:ship:audit'),
      isDeliver: checkPermission('order:ship:deliver'),
      isCheckLogistics: checkPermission('order:ship:check_logistics'),
      isEditLogistics: checkPermission('order:ship:edit_logistics'),
      isPush: checkPermission('order:ship:push'),
      isExport: checkPermission('order:ship:export'),
      isExportShipOrderDetail: checkPermission('order:ship:export_ship_detail'),
      isCancel: checkPermission('order:ship:cancle_shipped'),
      selectedRowKeys: [],
      selectedRows: [],
      spinning:false,
      cancelVisible:false,
      cancelLoading:false,
      cancelRemark:'',
      k3Data: {},
      managerList: [],
      visibleK3: false,
      formRule: {
        bearId:[{ required: true, message: '请选择营业担当', trigger: 'change' }],
      },
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        //type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
     searchDataObj(){
      let obj = {
        ...this.searchData,
        ids:this.selectedRowKeys
      }
      return obj

    }
  },
  methods: {
    cancelShipInfo(){
      this.cancelLoading = true
      const param = {
        ids: this.selectedRows.map((item) => item.id),
        cancelRemark: this.cancelRemark
      }
      this.axios.post('/api/order/system/orderShipInfo/toBatchCancel', param).then((res) => {
        if (res.code == 200) {
          this.cancelLoading = false
          this.$message.success(res.message)
          this.cancelVisible = false
          this.cancelRemark = ''
          this.selectedRowKeys = []
          this.getData()
        }
      }).catch((e)=>{
        console.log(e)
        this.cancelLoading = false
      })
    },
    startDownLoad(){
      this.spinning = true
    },
    downLoadDone (){
      this.spinning = false
    },
    //打开上游单据
    checkOrderInfo(row) {

      this.$refs.OrderInfoCheckModal.isShow(row, 'fhd')
    },


    getList() {
      this.$bus.$on('getList',() => {
        this.getData()
      })
    },

    /**
     * 获取表格数据
     */
    getData(num) {
      this.tableLoading = true
      if(num != 1){
        this.selectedRowKeys = []
        this.selectedRows = []
      }
      listOrderShipInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        shippingOrderStatus: 2,
        deliveryStatus: 1,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
               let list = []
            for(let i=0;i<records.length;i++){
              if(!records[i].orderCode){
                records[i].orderCode=i
              }else {
                let code = records[i].orderCode.split('，')
                for(let x=0; x<code.length; x++){
                  list.push(code[x])
                }
              }
              records[i].orderCode = list
              list = []
            }
            this.tableData = records
            this.searchData.shippingOrderStatus = 2
            this.searchData.deliveryStatus = 1
          }
        })
        .finally(() => (this.tableLoading = false))
    },
 /**
     * 筛选查询
     */
    // getDataFilter() {
    //   this.page = Object.assign({}, pageSource)
    //   this.getData()
    // },
     getDataFilter() {
      this.page.current = 1;
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10
      this.$nextTick( () => {
        this.$refs.pcd.setPCD();
      })
      this.getData()
    },

    /**
     * 更改分页
     */
    // changeTable(pagination) {
    //   this.page = pagination
    //   this.getData()
    // },
     changeTable(pagination) {
      this.page = pagination
      this.getData(1)
    },

    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'ship':

          if (_this.selectedRows.length  > 1) {
            return _this.$message.warning('请选择一条数据')
          }
          _this.$refs.OrderShipInfoEditModal.setRowData(_this.selectedRows[0], 'ship')
          break
        case 'improve_logistics':

          if (_this.selectedRows.length  > 1) {
            return _this.$message.warning('请选择一条数据')
          }
          _this.$refs.ImproveLogistics.getData(_this.selectedRows[0], 'improve_logistics')
          break
        case 'view_logistics':

          if (_this.selectedRows.length > 1 ) {
            return _this.$message.warning('请选择一条数据')
          }
          _this.$refs.CheckLogistics.getData(_this.selectedRows[0], 'view_logistics')
          break
        case 'del':

          if (_this.selectedRows.length  > 1) {
            return _this.$message.warning('请选择一条数据')
          }
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderShipInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':

          if (_this.selectedRows.length  > 1) {
            return _this.$message.warning('请选择一条数据')
          }
          _this.$refs.OrderShipInfoEditModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'audit':
          if (_this.selectedRows.length  > 1) {
            return _this.$message.warning('请选择一条数据')
          }
          _this.$refs.InvoiceReview.show(_this.selectedRows[0], 'audit')
          break
        // case 'push_k3':
        //
        //   const arr = []
        //   const pushcode = []
        //   const dealerName = []
        //   for (var i = 0; i < this.selectedRows.length; i++) {
        //     if (arr.indexOf(this.selectedRows[i].supplierCode) === -1) {
        //       arr.push(this.selectedRows[i].supplierCode)
        //     }
        //     if(this.selectedRows[i].sendK3){
        //       pushcode.push(this.selectedRows[i].shipCode)
        //     }
        //     if(dealerName.indexOf(this.selectedRows[i].dealerName) === -1){
        //       dealerName.push(this.selectedRows[i].dealerName)
        //     }
        //   }
        //   if (arr.length > 1) {
        //     return this.$message.warning('请选择同一供应商')
        //   }
        //
        //   if (dealerName.length > 1) {
        //     return this.$message.warning('请选择同一经销商')
        //   }
        //
        //   if(pushcode.length >0){
        //     return this.$message.warning('请勿重复推送')
        //   }
        //
        //   const billIds = this.selectedRows.map((item) => item.id).join(',');
        //
        //   const p = {
        //     billIds: billIds,
        //   }
        //
        //   this.axios.get(`/api/order/pushk3/batbatchPushK3`, {params: p}).then(res => {
        //     this.$message.success(res.message)
        //     this.$bus.$emit('getList')
        //     this.visible = false
        //     //this.$emit('onSubmit',this.order)
        //   })
        //
        //   _this.$refs.PushK3.getData(this.selectedRows);
        //   break
        case 'batchCancel':
          _this.$confirm({
            title: '警告',
            content: `取消发货单之后，订单货款会退回至经销商账户中，是否继续？`,
            okText: '确定',
            okType: 'primary',
            cancelText: '取消',
            onOk() {
              _this.cancelVisible = true
            },
            onCancel () {
              console.log('Cancel')
            }
          })
          break
      }
    },

    // 推送K3
    pushK3() {
      const arr = []
      const pushcode = []
      const dealerName = []
      for (var i = 0; i < this.selectedRows.length; i++) {
        if (arr.indexOf(this.selectedRows[i].supplierCode) === -1) {
          arr.push(this.selectedRows[i].supplierCode)
        }
        if(this.selectedRows[i].sendK3){
          pushcode.push(this.selectedRows[i].shipCode)
        }
        if(dealerName.indexOf(this.selectedRows[i].dealerName) === -1){
          dealerName.push(this.selectedRows[i].dealerName)
        }
      }
      if (arr.length > 1) {
        return this.$message.warning('请选择同一供应商')
      }
      if (dealerName.length > 1) {
        return this.$message.warning('请选择同一经销商')
      }
      if(pushcode.length >0){
        return this.$message.warning('请勿重复推送')
      }
      this.k3Data.billIds = this.selectedRows.map((item) => item.id).join(',');
      // 获取员工数据
      this.axios.get('/api/dealer/dealer/dealerInfo/getAccountExcutive/'+this.selectedRows[0].dealerId).then((res) => {
        this.managerList = res.body
      })
      this.visibleK3 = true;
    },

    handleOkCharge(){
      this.$refs.formCharge.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.confirmLoading = true
        const params = {
          billIds: this.k3Data.billIds,
          bearId: this.k3Data.bearId
        }

        this.axios.get(`/api/order/pushk3/batbatchPushK3`, {params: params}).then(res => {
          this.$message.success(res.message)
          this.$bus.$emit('getList')
          this.visibleK3 = false
        }).finally(() => {
          this.confirmLoading = false
          this.visibleK3 = false
          this.k3Data = {}
          this.managerList = []
        })
      })
    },

    handleCanceCharge(){
      this.confirmLoading = false
      this.visibleK3 = false
      this.k3Data = {}
      this.managerList = []
    },

  },
  created() {
    this.getData()
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
