/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-31 16:38:50
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-14 18:07:17
 */
export const columns = [
  {
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    width: 200,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '供应商',
    dataIndex: 'supplierName',
    key: 'supplierName',
    width: 200,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '发货单号',
    dataIndex: 'shipCode',
    key: 'shipCode',
    width: 200,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '舜天发货单号',
    dataIndex: 'saintyCode',
    key: 'saintyCode',
    width: 150,
    align: 'center',
    scopedSlots: { customRender: 'saintyCode'}
    // ellipsis:true
  },
  {
    title: '订单号',
    dataIndex: 'orderCode',
    key: 'orderCode',
    width: 170,
    align: 'center',
    scopedSlots: { customRender: 'orderCode'}
  },
  {
    title: '销售合同号',
    dataIndex: 'xsHeTong',
    key: 'xsHeTong',
    width: 170,
    align: 'left'
  },
  {
    title: '采购合同号',
    dataIndex: 'cgHeTong',
    key: 'cgHeTong',
    width: 170,
    align: 'left'
  },
  {
    title: '实际支付价',
    dataIndex: 'actualPrice',
    key: 'actualPrice',
    align: 'center',
    width: 100,
    scopedSlots: {customRender: 'actualPrice'}
  },
  // {
  //   title: '订单号',
  //   dataIndex: 'saintyCode',
  //   key: 'saintyCode',
  //   width: 150,
  //   align: 'center',
  //   scopedSlots: { customRender: 'saintyCode'}
  // },
  {
    title: '订单商品总数量',
    dataIndex: 'goodsCount',
    key: 'goodsCount',
    width: 100,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '已发商品数量',
    dataIndex: 'alreadySendGoodsCount',
    key: 'alreadySendGoodsCount',
    width: 100,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '实发商品发数量',
    dataIndex: 'realSendGoodsCount',
    key: 'realSendGoodsCount',
    width: 100,
    align: 'center',
    // ellipsis:true
  },
  {
    title: '剩余可发数量',
    dataIndex: 'remainingQuantity',
    key: 'remainingQuantity',
    width: 100,
    align: 'center',
  },
  {
    title: '此次发货数量',
    dataIndex: 'shipNum',
    key: 'shipNum',
    width: 100,
    align: 'center',
},
{
    title: '发货状态',
    dataIndex: 'deliveryStatus',
    key: 'deliveryStatus',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'deliveryStatus'}
},
{
    title: '审核状态',
    dataIndex: 'shippingOrderStatus',
    key: 'shippingOrderStatus',
    align:'center',
    width: 100,
    scopedSlots: { customRender: 'shippingOrderStatus'}
},


  // {
  //   title: '件数',
  //   dataIndex: 'caseNumber',
  //   key: 'caseNumber',
  //   align: 'center',
  //   // ellipsis:true
  // },
  // {
  //   title: '总重量',
  //   dataIndex: 'grossWeight',
  //   key: 'grossWeight',
  //   align: 'center',
  //   // ellipsis:true
  // },
  // {
  //   title: '运费',
  //   dataIndex: 'freight',
  //   key: 'freight',
  //   width:80,
  //   align: 'center',
  //   // ellipsis:true
  // },
  {
    title: '是否开票',
    dataIndex: 'flagInvoice',
    key: 'flagInvoice',
    width: 150,
    align: 'center',
    // ellipsis:true
    scopedSlots: {customRender: 'flagInvoice'}
  },
  {
    title: '收货信息',
    dataIndex: 'address',
    key: 'address',
    width: 300,
    ellipsis:true,
    scopedSlots: {customRender: 'address'}
  },
  {
    title: '制单人',
    dataIndex: 'createUser',
    key: 'createUser',
    align: 'center',
    width: 80,
  },
  {
    title: '发货日期',
    dataIndex: 'deliveryTime',
    key: 'deliveryTime',
    align: 'center',
    width: 200,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width: 200,
    key: 'createTime'
  },
  {
    title: '操作时间',
    dataIndex: 'modifyTime',
    align: 'center',
    width: 200,
    key: 'modifyTime'
  },
]
